const base64ToBuf = (b64) => Uint8Array.from(atob(b64), (c) => c.charCodeAt(null))

const enc = new TextEncoder()
const dec = new TextDecoder()

const getPasswordKey = (password) =>
  window.crypto.subtle.importKey("raw", enc.encode(password), "PBKDF2", false, ["deriveKey"])

const deriveKey = (passwordKey, salt, keyUsage) =>
  window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 250000,
      hash: "SHA-256",
    },
    passwordKey,
    { name: "AES-GCM", length: 256 },
    false,
    keyUsage
  )

async function decryptData(encryptedData, password) {
  try {
    const encryptedDataBuff = base64ToBuf(encryptedData)
    const salt = encryptedDataBuff.slice(0, 16)
    const iv = encryptedDataBuff.slice(16, 16 + 12)
    const data = encryptedDataBuff.slice(16 + 12)
    const passwordKey = await getPasswordKey(password)
    const aesKey = await deriveKey(passwordKey, salt, ["decrypt"])
    const decryptedContent = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      aesKey,
      data
    )
    return dec.decode(decryptedContent)
  } catch (e) {
    console.error("Decrypt failed", e)
    return ""
  }
}

async function unobfuscate() {
  const password = atob("MDE4ZGY4ZjgtZDFkMC03MDViLWE0YjEtYjU5M2YwZDZhYzE2")
  const data = "4DtOUQX4nmK/M+ncvUwtz/o8wL5TgVGvaSMUipLQoWZpyYJIG2KSWCKeigWH/2gHSkGryW9iA6UM5VB6xYW14g=="
  return await decryptData(data, password)
}

function showAlert({ parent, message, type }) {
  const alert = document.createElement("div")

  switch (type) {
    case "error":
      alert.className = "terminal-alert terminal-alert-error"
      break
    case "primary":
      alert.className = "terminal-alert terminal-alert-primary"
      break
    default:
      alert.className = "terminal-alert"
  }

  alert.innerHTML = message

  parent.querySelectorAll(".terminal-alert").forEach((alert) => {
    alert.remove()
  })

  parent.insertBefore(alert, parent.firstChild)
}

document.addEventListener("DOMContentLoaded", async () => {
  const siteKeyEl = document.querySelector("[data-sitekey]")
  const contactFormEl = document.querySelector("form[name='contact']")
  const emailEl = document.querySelector("[data-email]")

  if (emailEl) {
    emailEl.addEventListener("click", (event) => {
      const url = new URL(event.target.href)
      if (url.hash.length > 0) {
        event.preventDefault()
      }
    })
  }

  if (siteKeyEl) {
    const sitekey = siteKeyEl.dataset.sitekey
    turnstile.ready(function () {
      turnstile.render("#turnstile-widget", {
        sitekey: sitekey,
        action: "contact",
        callback: async function (token) {
          console.info(`Challenge success`, token)
          const email = await unobfuscate()
          emailEl.innerHTML = email
          emailEl.href = `mailto:${email}`

          if (contactFormEl) {
            const button = contactFormEl.querySelector("button")
            button.removeAttribute("disabled")
            button.classList.remove("btn-error", "btn-ghost")
            button.classList.add("btn-primary")

            const input = document.querySelector("input[name='turnstile-token']")
            input.value = token
          }
        },
      })
    })
  }

  if (contactFormEl) {
    contactFormEl.addEventListener("submit", async (event) => {
      console.info("Submitting form")
      event.preventDefault()
      const form = event.target
      const formData = new FormData(form)

      const showError = () => {
        showAlert({
          parent: form,
          message: "Something went wrong, please try again later",
          type: "error",
        })
      }

      if (formData.get("turnstile-token")?.length) {
        try {
          const response = await fetch(form.getAttribute("action"), {
            method: "POST",
            body: formData,
          })

          if (response.ok) {
            console.info("Form submitted")

            showAlert({
              parent: form,
              message: "Message sent successfully!",
              type: "primary",
            })

            form.reset()
          } else {
            console.error("Submission error", response.status, response.statusText)
            showError()
          }
        } catch (error) {
          console.error(error)
          showError()
        }
      } else {
        console.error("Missing Turnstile token")
        showError()
      }
    })
  }
})
