import Handlebars from "handlebars"
import { format } from "@formkit/tempo"

// Format an ISO date using Tempo
// usage: {{dateFormat creation_date format="MMMM YYYY"}}
Handlebars.registerHelper("dateFormat", function (context, block) {
  try {
    const f = block.hash.format || "MMMM D, YYYY"
    const datetime = new Date(context)
    return format(datetime, f)
  } catch (err) {
    console.warn(err)
    return context
  }
})

document.addEventListener("DOMContentLoaded", async () => {
  const baseURI = "https://www.randomerrata.com"
  const postsEl = document.querySelector("#posts")

  if (postsEl) {
    console.info(`Fetching latest articles from ${baseURI}`)

    try {
      const response = await fetch(`${baseURI}/latest.json`)
      const articles = await response.json()
      const source = document.querySelector("#articles-template").innerHTML
      const template = Handlebars.compile(source)
      postsEl.innerHTML = template(articles)
    } catch (err) {
      console.error(err)
    }
  }
})
