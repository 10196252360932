import "$styles/index.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

// Import all images from frontend/images
import images from "../images/**/*.{jpg,jpeg,png,svg,gif}"
Object.entries(images).forEach((image) => image)

console.info("Bridgetown is loaded!")

import "./blog-posts.js"
import "./obfuscate.js"
